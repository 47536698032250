<template>
  <el-select
    :value="selected"
    :remote-method="fetchAccounts"
    :loading="loading"
    :default-first-option="true"
    value-key="email"
    placeholder="Type to search"
    class="multi-select"
    filterable
    multiple
    remote
    @input="$emit('input', $event)"
    @remove-tag="handleRemove"
  >
    <el-option
      v-for="option in options"
      :key="option.id"
      :label="option.email"
      :value="option"
    >
      <span class="select-option-value">
        {{ option.email }}
      </span>
      <span class="select-option-value-alt">
        {{ option.name }}
      </span>
    </el-option>
  </el-select>
</template>

<script>
import RooofAccountAPI from '@/services/api/accounts'
import { getDistinctObjsByProp } from '@/utils'

export default {
  name: 'UserMultiSelect',
  props: {
    selected: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      options: []
    }
  },
  methods: {
    /**
     * Query the api for a list of RooofAccount's as the user
     * types into the search input.
     *
     * These will be used as the options list in the select
     * input.
     *
     * @param {String} query - property name
     */
    async fetchAccounts (query) {
      if (!query) {
        return
      }
      this.loading = true
      const params = {
        email__icontains: query
      }
      const response = await RooofAccountAPI.users.list(params)
      const options = this.formatOptions(response)
      // Merge and de-duplicate already-selected accounts with search results
      const selectedCopy = JSON.parse(JSON.stringify(this.selected))
      this.options = getDistinctObjsByProp('email', selectedCopy, options)

      // When the entered email doesn't exist, add it as an option
      // (this is similar to element-ui's `allow-create` prop, but
      // works with object values instead of primitives)
      const normalizedQuery = query.toLowerCase()
      if (!this.containsEmail(normalizedQuery)) {
        this.options.unshift({
          id: null,
          name: '',
          email: normalizedQuery
        })
      }
      this.loading = false
    },
    /**
     * Format the accounts as options
     * to be used with el-option.
     *
     * @param {Array} accounts
     * @returns {Array}
     */
    formatOptions (accounts) {
      return accounts.map(account => {
        return {
          id: account.id,
          name: account.name,
          email: account.email
        }
      })
    },
    /**
     * Determine if the user-provided value already
     * exists as a substring of the account select input
     * dropdown options.
     *
     * This is used to prevent displaying a duplicate
     * email address as the user types (only when all
     * other options have been exhausted do we show the
     * entered query value).
     *
     * @param {String} query
     * @returns {Boolean}
     */
    containsEmail (query) {
      return this.options.some(account => {
        if (!account.email) {
          return false
        }
        return account.email.includes(query)
      })
    },
    /**
     * Handler for `remove-tag` event triggered when
     * a tag is removed. Clears the dropdown option
     * to keep things tidy.
     *
     * @param {Object} item
     */
    handleRemove (item) {
      this.options = this.options.filter(el => el.email !== item.email)
    }
  }
}
</script>

<style scoped>
.el-select {
  width: 500px;
}
.select-option-value {
  float: left;
}
.select-option-value-alt {
  float: right;
  padding-left: 20px;
  padding-right: 20px;
  color: #949ba5;
  font-size: 13px;
}
</style>
