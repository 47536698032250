<template>
  <div id="user-add-email-dialog">
    <el-dialog
      :visible="show"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="true"
      title="Invite Users by Email"
      width="700px"
      @close="close"
    >
      <el-row class="info">
        <el-col>
          Use this form to add users by email address and select
          company/property permissions. The chosen permissions will
          be applied to <b>each user in the list</b>.
        </el-col>
      </el-row>
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
      >
        <el-row
          type="flex"
          class="user-select"
        >
          <el-col :span="6">
            User Emails
          </el-col>
          <el-col :span="18">
            <el-form-item prop="users">
              <user-select
                :selected="form.users"
                @input="form.users = $event"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="24">
            <el-form-item prop="permissions">
              <user-permissions
                :company="company"
                :permissions.sync="form.permissions"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row
          class="submit"
          type="flex"
          justify="end"
        >
          <el-button
            @click="close"
          >
            Cancel
          </el-button>
          <el-button
            type="primary"
            @click="submit"
          >
            Submit
          </el-button>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
/**
 * UserAddEmailDialog
 *
 * Allows staff to add a list of users who are to be
 * invited to portal, and specify permissions for each
 * account.
 */
import UserMultiSelect from './UserMultiSelect'
import UserPermissions from './UserPermissions'
import regex from '@/utils/regex'

export default {
  name: 'UserAddEmailDialog',
  components: {
    'user-select': UserMultiSelect,
    'user-permissions': UserPermissions
  },
  props: {
    company: {
      type: Object,
      required: true
    },
    users: { // for validation
      type: Array,
      required: true
    },
    show: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      form: {
        users: [],
        permissions: {
          company: false,
          properties: []
        },
        sendNewsletter: true
      },
      rules: {
        users: [
          {
            validator: (rule, value, callback) => {
              if (!value.length) {
                return callback(new Error('Please add at least one user'))
              }
              // Prevent adding duplicate users
              for (const user of this.users) {
                if (value.some(el => el.email === user.email)) {
                  return callback(new Error(`${user.email} has already been added`))
                }
              }
              // Ensure each email is well-formed
              for (const user of value) {
                if (!regex.email.test(user.email)) {
                  return callback(new Error(`${user.email} is not a valid email address`))
                }
              }
              return callback()
            },
            trigger: 'blur'
          }
        ],
        permissions: [
          {
            validator: (rule, value, callback) => {
              if (!value.company && !value.properties.length) {
                return callback(new Error('Please add at least one permission'))
              }
              return callback()
            },
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    /**
     * Validate the form and emit 'submit' event
     * to be handled by the parent.
     */
    submit () {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.$emit('submit', JSON.parse(JSON.stringify(this.form)))
          this.close()
        }
      })
    },
    /**
     * Clear all form fields and emit `close` event.
     */
    close () {
      this.$refs['form'].resetFields()
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 20px;
}
.info {
  padding-bottom: 2rem;
  word-break: break-word;
}
.user-select {
  padding-bottom: 1rem;
}
.submit {
  padding-top: 2rem;
}
</style>
