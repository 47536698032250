<template>
  <el-select
    :value="selected"
    :remote-method="fetchProperties"
    :loading="loading"
    :disabled="disabled"
    value-key="id"
    placeholder="Type to search"
    class="multi-select"
    filterable
    multiple
    remote
    @input="$emit('input', $event)"
  >
    <el-option
      v-for="option in options"
      :key="option.id"
      :label="option.name"
      :value="option"
    />
  </el-select>
</template>

<script>
import RooofAPI from '@/services/api/rooof'
import { getDistinctObjsByProp } from '@/utils'

export default {
  name: 'PropertyMultiSelect',
  props: {
    company: {
      type: Object,
      required: true
    },
    selected: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      options: []
    }
  },
  computed: {
    companyId () {
      return this.company.id
    }
  },
  async created () {
    // Add already-selected properties as options
    // so that the property names can be displayed
    // in the select input
    this.options = JSON.parse(JSON.stringify(this.selected))
  },
  methods: {
    /**
     * Query the api for a list of properties as the user
     * types into the search input.
     *
     * These will be used as the options list in the select
     * input.
     *
     * @param {String} query - property name
     */
    async fetchProperties (query) {
      if (!query) {
        return
      }
      this.loading = true
      const response = await RooofAPI.companies.propertySummary(this.companyId, { q: query })
      const options = this.formatOptions(response)
      // Merge and de-duplicate already-selected properties with search results
      this.options = getDistinctObjsByProp('id', options, this.selected)
      this.loading = false
    },
    /**
     * Format the properties as options
     * to be used with el-option.
     *
     * @param {Array} properties
     * @returns {Array}
     */
    formatOptions (properties) {
      return properties.map(property => {
        return {
          id: property.id,
          name: property.name
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-select {
  width: 100%;
}
</style>
