<template>
  <div class="user-permissions">
    <el-row type="flex" align="middle">
      <el-col :span="6">
        <el-switch
          :value="permissions.company"
          inactive-text="All Properties"
          class="company-permissions"
          @input="updatePermissions($event, permissions.properties)"
        />
      </el-col>
      <el-col :span="18">
        <property-select
          v-show="!permissions.company"
          :company="company"
          :selected="permissions.properties"
          @input="updatePermissions(permissions.company, $event)"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
/**
 * UserPermissions
 *
 * Allows setting the permissions for an account.
 *
 * permissions: {
 *   company: false,     // grants access to all properties (overrides property permissions)
 *   properties: [       // grants access to specific properties
 *     {
 *       id: 14396,
 *       name: '01 Test Property'
 *     },
 *     {
 *       id: 14397,
 *       name: '02 Test Property'
 *     }
 *   ]
 * }
 *
 * Events:
 *
 * @update:permissions: update the selected permissions
 */
import PropertyMultiSelect from './PropertyMultiSelect'

export default {
  name: 'UserPermissions',
  components: {
    'property-select': PropertyMultiSelect
  },
  props: {
    company: {
      type: Object,
      required: true
    },
    permissions: {
      type: Object,
      required: true
    }
  },
  methods: {
    /**
     * Emit `update:permissions` event which
     * will be handled by the parent.
     *
     * @param {Boolean} company
     * @param {Array} properties
     */
    updatePermissions (company, properties) {
      const newPermissions = {
        company,
        properties
      }
      this.$emit('update:permissions', newPermissions)
    }
  }
}
</script>

<style scoped>
::v-deep .el-switch__label {
    font-weight: 400;
    color: #415972;
  }
</style>
