<template>
  <div id="portal-invite">
    <div class="header">
      <h2>
        Web Reporting Invitations - {{ company.human_name }}
      </h2>
      <div class="add-users">
        <el-button
          type="primary"
          icon="el-icon-plus"
          size="medium"
          @click="showUserAddEmailDialog = true"
        >
          Invite Users by Email
        </el-button>
      </div>
    </div>

    <div class="subtitle">
      Use the buttons to the right to invite new users to portal.
      As you add users, they will appear below.
    </div>

    <el-card>
      <invite-list
        :company="company"
        :users="users"
        @update:permissions="updatePermissions"
        @remove-user="removeUser"
      />
      <el-row
        type="flex"
        align="center"
        class="controls"
      >
        <el-col :span="4">
          <el-switch v-model="sendNewsletter" active-text="Add to Newsletter" />
        </el-col>
        <el-col>
          <el-alert
            type="info"
            show-icon
            class="newsletter-alert"
            :closable="false"
          >
            <template #title>
              Only new users will be added to the newsletter.
            </template>
          </el-alert>
        </el-col>
      </el-row>
      <el-row type="flex" class="controls">
        <el-col>
          <el-button
            type="success"
            size="medium"
            :disabled="!users.length"
            @click="validateUsers"
          >
            Validate
          </el-button>
          <el-button
            type="primary"
            size="medium"
            :disabled="!valid"
            @click="sendInvitations"
          >
            Send Invitations
          </el-button>
        </el-col>
      </el-row>
    </el-card>

    <user-add-email-dialog
      :company="company"
      :users="users"
      :show="showUserAddEmailDialog"
      @close="showUserAddEmailDialog = false"
      @submit="addUsers"
    />
  </div>
</template>

<script>
import PortalInviteList from './_components/PortalInviteList'
import UserAddEmailDialog from './_components/UserAddEmailDialog'

import RooofAccountAPI from '@/services/api/accounts'
import RooofAPI from '@/services/api/rooof'
import CraigslistAPI from '@/services/api/craigslist'

export default {
  name: 'PortalInvite',
  components: {
    'invite-list': PortalInviteList,
    'user-add-email-dialog': UserAddEmailDialog
  },
  props: {
    company: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      showUserAddEmailDialog: false,
      users: [],
      sendNewsletter: true
    }
  },
  computed: {
    valid () {
      return this.users.length > 0 && this.users.every(user => user.valid === true)
    }
  },
  methods: {
    /**
     * Handles the `submit` event from the user add modal.
     *
     * Receives a list of users and a permissions object
     * which will get applied to each user.
     *
     * @param {Object} data
     * @param {Array} data.users
     * @param {Object} data.permissions
     * @param {Boolean} data.sendNewsletter
     */
    addUsers (data) {
      for (const userToAdd of data.users) {
        // Add permissions, making sure to use a copy so that they can be edited
        userToAdd.permissions = JSON.parse(JSON.stringify(data.permissions))
      }
      this.users = this.users.concat(data.users)
      this.sendNewsletter = data.sendNewsletter
      this.showUserAddEmailDialog = false
    },
    /**
     * Update the permissions for the selected user.
     *
     * @param {Number} index
     * @param {Object} newPermissions
     */
    updatePermissions (index, newPermissions) {
      const user = this.users[index]
      this.$set(user, 'permissions', newPermissions)
      this.$set(this.users, index, user)
    },
    /**
     * Remove the user at `index`.
     *
     * @param {Number} index
     */
    removeUser (index) {
      this.users.splice(index, 1)
    },
    /**
     * Send a request to the api to validate each user.
     *
     * Resets user validation state each time it's called.
     */
    async validateUsers () {
      const promises = []

      for (const user of this.users) {
        this.$set(user, 'valid', false)
        this.$set(user, 'info', [])
        this.$set(user, 'errors', [])

        if (user.id) {
          user.info.push('This account already exists')
          promises.push(RooofAccountAPI.portalInvite.validateUser(user.id))
        } else {
          user.valid = this.hasPermissions(user)
          if (!user.valid) {
            user.errors.push('Missing permissions')
          }
        }
      }
      const responses = await Promise.all(promises)

      for (const response of responses) {
        const user = this.users.find(user => user.id === response.account.id)
        this.validateUser(user, response)
      }
    },
    /**
     * Validate a single user account prior to them
     * being sent an invitation.
     *
     * @param {Object} user - local user object
     * @param {Object} result - validate endpoint response
     */
    validateUser (user, result) {
      user.valid = result.valid
      const account = result.account
      const invites = account.portal_invites

      if (!this.hasPermissions(user)) {
        user.errors.push('Missing permissions')
        return
      }
      if (invites.length > 0) {
        user.info.push(`This account has been invited ${invites.length} times`)
      }
      if (!user.valid) {
        if (account.is_staff) {
          user.errors.push('Staff users are not eligible')
        } else if (!account.is_active) {
          user.errors.push('This account is inactive')
        } else if (account.has_password || account.last_login !== null) {
          user.errors.push('This account has already been set up for portal')
        } else if (this.hasPendingInvite(invites)) {
          user.errors.push('This account has a pending invitation')
        }
      }
    },
    /**
     * Given a list of invitations for an account,
     * determine if they have a pending invite.
     *
     * @param {Array} invites
     * @returns {Boolean}
     */
    hasPendingInvite (invites) {
      return invites.some(invite => {
        const invitedAt = this.$moment(invite.timestamp)
        const expiresAt = this.$moment().subtract(30, 'days')
        return !invite.accepted && invitedAt >= expiresAt
      })
    },
    /**
     * Determine if the given user has company/property
     * permissions assigned.
     *
     * @param {Object} user
     * @returns {Boolean}
     */
    hasPermissions (user) {
      return user.permissions.company || user.permissions.properties.length > 0
    },
    /**
     * Send a portal invitation email to each user.
     *
     */
    async sendInvitations () {
      const promises = []

      // top level post engine group
      const groups = (await CraigslistAPI.groups.list({ company_id: this.company.id }))[0].children
      const topLevelGroup = groups.find(group => group.name === `${this.company.human_name} Reporting`).id

      for (const user of this.users) {
        const data = {
          email: user.email,
          name: user.name,
          postengine_groups: [],
          rooof_properties: []
        }
        if (user.permissions.company) {
          data.postengine_groups.push(topLevelGroup)
        } else {
          data.rooof_properties = user.permissions.properties.map(prop => prop.id)
        }
        promises.push(RooofAccountAPI.portalInvite.createInvite(data))
        if (this.sendNewsletter) {
          promises.push(RooofAPI.newsletter.create({ email: user.email }))
        }
      }
      const responses = await Promise.allSettled(promises)
      this.parseResponses(responses)
    },
    /**
     * Parse the invitation response results.
     *
     * On success, the user will be removed from the list.
     * On failure, an error message will be displayed and
     * the user will remain on the list so that the request
     * can be re-tried.
     *
     * @param {Array} responses - results of Promise.allSettled()
     */
    parseResponses (responses) {
      const results = {
        sent: 0,
        failed: 0
      }
      for (const [index, response] of responses.entries()) {
        const user = this.users[index]

        if (response.status === 'fulfilled') {
          results.sent += 1
        }
        if (response.status === 'rejected') {
          results.failed += 1
          user.valid = false
          user.errors = []

          if (response.reason.response) {
            user.errors.push(response.reason.response.data.non_field_errors[0])
          } else {
            user.errors.push('Unknown error, please try again')
            console.error(response.reason)
          }
        }
      }
      const type = results.failed ? 'warning' : 'success'
      const message = results.failed
        ? `Some invitations were not sent (${results.failed} failed, ${results.sent} succeeded).`
        : `Success! ${results.sent} invitations sent.`

      this.$msgbox({
        type,
        message,
        title: 'Results',
        showClose: false,
        closeOnClickModal: false,
        closeOnPressEscape: false
      })
      // Remove successful results, leave errors so the user can retry
      this.users = this.users.filter(user => !user.valid)
    }
  }
}
</script>

<style lang="scss" scoped>
$color-primary: #1a7dc6;
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.subtitle {
  font-size: 0.9rem;
  margin-bottom: 2rem;
}
.controls {
  margin-top: 2em;
}
.el-switch {
  margin-top: .5em;
}
.newsletter-alert {
  margin-bottom: 1em;
  background-color: #ffffff;
  color: $color-primary;
}
</style>
