<template>
  <div id="portal-invite-list">
    <el-row
      type="flex"
      align="middle"
      class="header-row"
    >
      <el-col :span="6">
        <h3>User</h3>
      </el-col>
      <el-col :span="18">
        <h3>Permissions</h3>
      </el-col>
    </el-row>
    <el-row v-if="!users.length" class="no-data">
      <el-col>
        No users added.
      </el-col>
    </el-row>

    <div
      v-for="(user, index) in users"
      :key="user.email"
      class="user-row"
    >
      <el-row type="flex" align="middle">
        <el-col :span="6">
          <div class="email">
            {{ user.email }}
          </div>
          <div v-if="user.name" class="name">
            {{ user.name }}
          </div>
          <div v-if="user.info && user.info.length" class="info">
            <el-tag
              v-for="info in user.info"
              :key="info"
              :disable-transitions="true"
              type="info"
              size="mini"
            >
              {{ info }}
            </el-tag>
          </div>
          <div v-if="user.errors && user.errors.length" class="error">
            <el-tag
              v-for="error in user.errors"
              :key="error"
              :disable-transitions="true"
              type="danger"
              size="mini"
            >
              {{ error }}
            </el-tag>
          </div>
          <div v-if="user.valid" class="error">
            <el-tag
              :disable-transitions="true"
              type="success"
              size="mini"
            >
              Valid
            </el-tag>
          </div>
        </el-col>

        <el-col :span="14">
          <user-permissions
            :company="company"
            :permissions="user.permissions"
            @update:permissions="$emit('update:permissions', index, $event)"
          />
        </el-col>

        <el-col :span="4" class="remove-user">
          <el-button
            size="mini"
            type="danger"
            @click="$emit('remove-user', index)"
          >
            Remove
          </el-button>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import UserPermissions from './UserPermissions'

export default {
  name: 'PortalInviteList',
  components: {
    'user-permissions': UserPermissions
  },
  props: {
    company: {
      type: Object,
      required: true
    },
    users: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
h3 {
  margin-bottom: 0.3rem;
  margin-top: 0.3rem;
}
.no-data {
  padding-left: 0.5rem;
  padding-top: 2rem;
  color: #959595;
}
.header-row, .user-row {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 5px;
}
.user-row {
  padding-top: .5rem;
  padding-bottom: .5rem;

  &:nth-child(odd) {
    background-color: #f6f6f6;
  }
  .email, .name, .info, .error {
    padding-bottom: .2rem;
  }
  .email {
    font-weight: 600;
  }
  .name {
    color: #959595;
    font-size: 0.9rem;
  }
  .remove-user {
    padding-left: 1rem;
  }
}
</style>
